import apiClient from "./apiClient";
//import constant from './constants';

export default {
  getAuthentication() {
    alert('Authentication');
    return null;
    //return apiClient.get("/home/doctorslist");
  },

  AuthenticatUser(data) {
    return apiClient.post("/Auth/Authenticate", data);
  },

  getUserList(data) {
    return apiClient.post("/list/getUserList", data);
  },

  saveUser(data) {
    return apiClient.post("/masters/saveUser", data);
  },

  changePassword(data) {
    return apiClient.post("auth/changePassword", data);
  },
};
