import notifications from "@/components/common/notifications";
import listAPI from "@/api/listAPI.js";
import userAPI from "@/api/userAPI.js";
//import patientAPI from "@/api/patientAPI.js";
import store from "@/store/index";
import validations from "@/validations/index";
import Header from "@/components/Header.vue";
import permissions from "@/api/permissions.js";

export default {
  components: {
    Header,
    notifications,
  },
  data: () => ({
    // Pagination
    page: 1,

    // Dialog box
    adminEditPopup: false,
    formDataEdit: {},
    formData: {},
    //status: ["Active", "Inactive", "All"],
    status: [
      { text: "Active", value: "T" },
      { text: "Inactive", value: "F" },
      { text: "All", value: null },
    ],
    searchFormData: { search_string: "", status: "T" },

    // options: { page: 1},
    options: {},
    totalRecords: 0,
    tblLoading: false,
    vrAppointments: [],
    headers: [
      //   { sortable: false, text: "#", value: "id" },
      { sortable: false, text: "NAME", value: "full_name" },
      { sortable: false, text: "USERNAME", value: "username" },
      { sortable: false, text: "EMAIL", value: "email" },
      { sortable: false, text: "AFFILIATE", value: "affiliate" },
      { sortable: false, text: "ROLE", value: "role_name" },
      { sortable: false, text: "ACTIVE", value: "is_active_display" },
      { sortable: false, text: "", value: "action" },
    ],
    roles: null,
    vrYesNo: [
      { text: "Yes", value: "T" },
      { text: "No", value: "F" },
    ],

    //---------------------------------------------------
    //Used for permission handling
    //---------------------------------------------------
    permissions: {},
    //---------------------------------------------------
  }),

  watch: {
    options: {
      handler() {
        this.loadUsers();
      },
      deep: true,
    },
  },
  methods: {
    addUserPopup() {
      this.formData = {};
      this.adminEditPopup = true;
    },
    editUser(item) {
      this.formData = Object.assign({}, item);

      //Hide the popup
      this.adminEditPopup = true;
    },
    SaveData: function (e) {
      e.preventDefault();

      //----------------------------------------------
      //Required field validations
      let isValid = true;

      if (isValid) {
        isValid = validations.hasValue(this.formData.username);
      }
      if (isValid) {
        isValid = validations.hasValue(this.formData.first_name);
      }
      if (isValid) {
        isValid = validations.hasValue(this.formData.last_name);
      }
      if (isValid) {
        isValid = validations.hasValue(this.formData.email);
      }
      if (isValid) {
        isValid = validations.hasValue(this.formData.role_id);
      }
      if (isValid) {
        isValid = validations.hasValue(this.formData.is_active);
      }
      if (!!this.formData.id == false) {
        if (isValid) {
          isValid = validations.hasValue(this.formData.password);
        }
      }
      if (!isValid) {
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.visible = store.state.required.visible;
        return false;
      }
      //----------------------------------------------

      userAPI
        .saveUser(this.formData)
        .then((response) => {
          if (response.data !== null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;

            if (response.data.status) {
              this.formData = {};
              this.adminEditPopup = false;
            }
          }
        })
        .then(() => {
          //Refresh scheduling table with updated data
          this.loadUsers();

          //Hide the popup
          this.editEvent = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //this[vrLoadingControlName] = false;
        });
    },

    loadUsers() {
      this.tblLoading = true;
      this.searchFormData.pagination = this.options;
      userAPI
        .getUserList(this.searchFormData)
        .then((response) => {
          if (response.data.results !== null) {
            this.vrAppointments = response.data.results.Users;
            this.totalRecords = response.data.results.UserCount.total_count;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.tblLoading = false;
        });
    },
    clearAllFields() {
      this.searchFormData = { search_string: "", status: "T" };
      //this.options.page = 1;

      //Load all users
      this.loadUsers();
    },
    onSubmit(e) {
      e.preventDefault();

      //Load all users
      this.loadUsers();
    },
  },
  beforeMount() {
    listAPI
      .getRolelList()
      .then((response) => {
        if (response.data.results !== null)
          this.roles = response.data.results.roles;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  },
  computed: {
    vrNotify() {
      return store.state.notify;
    },
  },
  mounted() {},
  created() {
    //Get permissions for logged in user
    this.permissions = permissions.getPermissions("search");
  },
};
